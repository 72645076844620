<template>
  <div class="home">
    
    <div class="container-fluid">
      <principal-navbar class="row" />
    </div>

    <!-- ABOUT US -->
    <principal-price type="true" />

  </div>
</template>

<script>
export default {
  name: 'Home',
  metaInfo() {
    return { 
      title: "Aceros Prolider SA de CV - Cotización",
      meta: [
        { name: 'description', content:  'Contacta a uno de nuestros asesores para proporcionarte una atención personalizada para brindarte soluciones a tu medida.' },
        { property: 'og:title', content: "Aceros Prolider SA de CV - Cotización" },
        { property: 'og:site_name', content: 'Aceros Prolider SA de CV - Cotización' },
        { property: 'og:type', content: 'website'},
      ]
    }
  },
  components: {
    PrincipalNavbar: () => import('@/components/PrincipalNavbar.vue'),

    PrincipalPrice: () => import('@/components/PrincipalPrice.vue'),
  }
}
</script>